import React, { useCallback } from "react";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignAppointments,
  getCampaignAppointmentThunk
} from "redux/slices/campaignSlice";
import CampaignAssetsTable from "./table";

const header = [
  { id: "id", name: "Appointment Id" },
  { id: "driver_name", name: "Driver Name" },
  { id: "workshop_id", name: "Workshop Id" },
  { id: "status", name: "Status" },
  { id: "datetime", name: "Booking DateTime" },
  { id: "action", name: "Action" }
];

const CampaignAppointment: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const campaignAppointments = useSelector(getCampaignAppointments);

  const getAllCampaignsAppointment = useCallback(
    (page: number) => {
      if (!!accessToken) {
        dispatch(
          getCampaignAppointmentThunk({
            accessToken,
            page,
            campaignId: campaignAppointments.data[0].campaign_id
          })
        );
      }
    },
    [dispatch, accessToken, campaignAppointments]
  );
  console.log();
  return (
    <MDBox p={2}>
      {!!campaignAppointments && campaignAppointments.data ? (
        <CampaignAssetsTable
          header={header}
          rows={campaignAppointments}
          onReload={() => {
            getAllCampaignsAppointment(1);
          }}
          campaignId={
            campaignAppointments && campaignAppointments.data[0]
              ? campaignAppointments.data[0].campaign_id
              : 0
          }
          onPageChanged={(newPage: number) =>
            getAllCampaignsAppointment(newPage+1)
          }
        />
      ) : (
        <LoadingComponent />
      )}
    </MDBox>
  );
};
export default CampaignAppointment;
